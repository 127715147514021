import React, { useState, useEffect, useRef, forwardRef, useMemo, useImperativeHandle } from "react";
import { Icon } from "../Components/Icons";
import { ConnectLine, EndArrow } from "./Landing";
import Timer, { TimerMethods } from "../Components/Timer";
import GreenButton from "../Components/GreenButton";
import greenArrow from "../assets/green-arrow.svg";
import phone from "../assets/phone-icon.svg";
import blueCheck from "../assets/blue-check-icon.svg";
import completeBackground from "../assets/completed-background.svg";
import whiteArrow from "../assets/white-arrow.svg";
import x from "../assets/white-x.svg";
import shareIcon from "../assets/share-icon.svg";
import chevronRight from "../assets/chevron-right.svg";
import { ShareModal } from "./Share";
import { IoTrophy } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Platform } from "../Components/Platform";
import CountryList from "country-list";
import { HiChevronRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { FaceIcon } from "../Components/faceIcon";
import { LanguageSwitcher } from "../Components/LanguageSwitcher";
import { ArmsIcon } from "../Components/armsIcon";
import { SpeechIcon } from "../Components/speechIcon";

interface RoundData {
    /**
     * @property {number} phaseNumber Order in which this phase will appear
     */
    phaseNumber: number
    /**
     * @property {number} duration The amount of time (in ms) that each round will last
     */
    duration: number
    /**
     * @property {boolean[][]} answers Provide the answers for each of the rounds within the phase
     */
    answers: boolean[][]
    /**
     * @property {"fast" | "befast"} type Defines the phase type that is being played
     */
    type: "fast" | "befast"
}


function Game(){
    const { t, i18n } = useTranslation(['translation']);

    const countries = CountryList.getNames();
    const platform = new Platform();
    const [loaded, setLoaded] = useState(0);
    const [activeGame, setActiveGame] = useState(false);
    const timer = useRef<TimerMethods>(null);
    const [answers, setAnswers] = useState<boolean[]>([false, false, false, false]); // Initial answers
    const [currentRound, setCurrentRound] = useState(0);
    const [correctHighlighted, setCorrectHighlighted] = useState(false);
    const [roundTimeout, updateTimeout] = useState<NodeJS.Timeout>();
    const [completed, setCompleted] = useState(false);
    const [incorrectAnswer, setIncorrectAnswer] = useState(false);

    const [formError, setFormError] = useState<string | null>(null);
    const [formPending, setFormPending] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const nameField = useRef<any>();
    const lbNameField = useRef<any>();
    const emailField = useRef<any>();
    const [countryField, setCountryField] = useState("");

    const [intermission, setIntermission] = useState(0);
    const [outro, setOutro] = useState(0);

    const [currentPhase, setCurrentPhase] = useState<RoundData>();

    const fastStaticIcons = useMemo(() => <IconRow loaded={5} answers={[true, true, true, true]} blue={false} />, []);
    const befastStaticIcons = useMemo(() => <IconRow loaded={5} answers={[true, true, true, true, true, true]} blue={false} />, []);

    const [time, setTime] = useState(0) // This is only for updating static timers with time from dynamic timer

    const [checkbox, setCheckbox] = useState(false);

    const [shareModal, setShareModal] = useState(false);

    const [privacyPolicy, setPrivacyPolicy] = useState(false);

    const phases: RoundData[] = [
        {
            phaseNumber: 1,
            duration: 5000,
            type: "fast",
            answers: platform.env === "local" ? [
                [true, true, true, true],
                [false, false, false, false],
                [false, true, false, false],
                [false, true, false, true],
                [false, true, true, true],
                [true, true, true, true]
            ]
            :
            [
                [false, false, false, false],
                [false, true, false, false],
                [false, true, false, true],
                [false, true, true, true],
                [true, true, true, true]
            ]
        },
        {
            phaseNumber: 2,
            duration: 3000,
            type: "fast",
            answers: platform.env === "local" ? [
                [true, true, true, true],

                [false, false, false, false],
                [false, true, false, false],
                [false, true, false, true],
                [false, true, true, true],
                [true, true, true, true]
            ]
            :
            [
                [false, false, false, false],
                [false, true, false, false],
                [false, true, false, true],
                [false, true, true, true],
                [true, true, true, true]
            ]
        },
        {
            phaseNumber: 3,
            duration: 2000,
            type: "fast",
            answers: platform.env === "local" ? [
                [true, true, true, true],

                [false, false, false, false],
                [false, true, false, false],
                [false, true, false, true],
                [false, true, true, true],
                [true, true, true, true]
            ]
            :
            [
                [false, false, false, false],
                [false, true, false, false],
                [false, true, false, true],
                [false, true, true, true],
                [true, true, true, true]
            ]
        },
        {
            phaseNumber: 4,
            duration: 4000,
            type: "befast",
            answers: platform.env === "local" ? [
                [true, true, true, true, true, true,],

                [false, false, false, false, false, false],
                [false, true, false, false, false, false],
                [false, true, false, true, false, false],
                [false, true, true, true, false, false],
                [false, true, true, true, true, false],
                [false, true, true, true, true, true],
                [true, true, true, true, true, true,]
            ]
            :
            [
                [false, false, false, false, false, false],
                [false, true, false, false, false, false],
                [false, true, false, true, false, false],
                [false, true, true, true, false, false],
                [false, true, true, true, true, false],
                [false, true, true, true, true, true],
                [true, true, true, true, true, true,]
            ]
        }
    ]

    useEffect(() => {
        let timeout = 500;

        setTimeout(() => {
            setLoaded(1);
        }, timeout);
        timeout += 100;

        setTimeout(() => {
            setLoaded(2);
        }, timeout);
        timeout += 100;

        setTimeout(() => {
            setLoaded(3);
        }, timeout);
        timeout += 100;

        setTimeout(() => {
            setLoaded(4);
        }, timeout);
        timeout += 100;

        setTimeout(() => {
            setLoaded(5);
        }, timeout)
    }, []);

    const startGame = () => {
        if (!activeGame && currentPhase === undefined){
            nextPhase(1);
            setActiveGame(true)

            nextRound(1);
            timer.current!.start();
        } else {
            if (currentPhase){
                setActiveGame(true);

                nextRound(1);
                timer.current!.start();
            }
        }
    }

    const nextPhase = (phase: number) => {
        const phaseData = phases.filter(phs => phs.phaseNumber === phase)[0];
        setCurrentPhase(phaseData);
    }

    const startNextPhase = () => {
        if (currentPhase){
            if (phases[currentPhase.phaseNumber - 1].type === "fast"){
                setIntermission(3);
                setTimeout(() => {
                    setIntermission(-1);
                }, 500);
            } else {
                if (answers.length === 4){
                    const roundAnswers = currentPhase.answers[0];
                    roundAnswers.sort(() => 0.5 - Math.random());

                    setAnswers(roundAnswers);
                }
                setIntermission(6);
                setTimeout(() => {
                    setIntermission(-2);
                }, 500);
            }
        }
    }

    const nextRound = (round: number) => {
        setCorrectHighlighted(false);
        setCurrentRound(round);

        var roundData = currentPhase

        if (roundData === undefined){
            roundData = phases.filter(phs => phs.phaseNumber === 1)[0];
        }

        if (roundData){
            const roundAnswers = roundData.answers[round - 1];
            roundAnswers.sort(() => 0.5 - Math.random());
            setAnswers(roundAnswers);

            updateTimeout(setTimeout(() => {

                if (round === roundData?.answers.length){
                    nextRound(1);
                } else {
                    nextRound(round + 1);
                }

            }, roundData.duration));
        }
    }

    const submitAnswer = () => {
        setCorrectHighlighted(true);

        const roundData = currentPhase;
        console.log(roundData);

        if (roundData){
            var success = true;

            for (var i = 0; i < roundData.answers[currentRound - 1].length; i++){
                if (!roundData.answers[currentRound - 1][i]){
                    success = false;
                }
            }

            if (success){
                timer.current!.stop();
                clearTimeout(roundTimeout);
                setCompleted(true);
                setIntermission(0);

                setTime(timer.current!.getTime());
            } else {
                if (!incorrectAnswer){
                    setTimeout(() => {
                        setIncorrectAnswer(false);
                        setCorrectHighlighted(false);
                    }, 2000);
                }
                setIncorrectAnswer(true);
                timer.current!.addTime(10);
            }
        }
    }

    const toIntermission = () => {
        if (currentPhase){
            if (currentPhase.phaseNumber === phases.length){
                setOutro(1);
                setTimeout(() => {
                    setOutro(2);
                }, 500);
            } else {
                if (phases[currentPhase.phaseNumber].type === "befast"){
                    setIntermission(4);
                    setTimeout(() => {
                        setIntermission(5);
                        setTimeout(() => {
                            setActiveGame(false);
                            setCompleted(false);
                            setCorrectHighlighted(false);
                            setCurrentPhase(phases[currentPhase.phaseNumber]);
                        }, 300);
                    }, 500);
                } else {
                    setIntermission(1);
                    setTimeout(() => {
                        setIntermission(2);
                        setTimeout(() => {
                            setActiveGame(false);
                            setCompleted(false);
                            setCorrectHighlighted(false);
                            setCurrentPhase(phases[currentPhase.phaseNumber]);
                        }, 300);
                    }, 500);
                }
            }
        }
    }

    const toggleCheckbox = () => {
        setCheckbox(!checkbox);
    }

    const submitScore = () => {
        const name = nameField.current?.getValue() ?? "";
        const lbName = lbNameField.current?.getValue() ?? "";
        const email = emailField.current?.getValue() ?? "";

        setFormError(null);

        if (name === ""){
            setFormError(i18n.t('leaderBoard.formError1'));
            return;
        }

        if (lbName === ""){
            setFormError(i18n.t('leaderBoard.formError2'));
            return;
        }

        if (email === ""){
            setFormError(i18n.t('leaderBoard.formError3'));
            return;
        }

        if (!checkbox){
            setFormError(i18n.t('leaderBoard.formError4'));
            return;
        }

        if (countryField === ""){
            setFormError(i18n.t('leaderBoard.formError5'));
            return;
        }

        setFormPending(true);

        if (platform.env === "local"){
            setTimeout(() => {
                setFormPending(false);
                setFormSubmitted(true);
            }, 3000);

            return;
        }

        var apiUrl = platform.endpoint! + new URLSearchParams({
            "ACT": platform.actId,
            "action": "add",
            "name": name,
            "lbName": lbName,
            "email": email,
            "time": time.toString(),
            "country": CountryList.getCode(countryField) ?? ""
        });

        fetch(apiUrl, {
            method: "get"
        }).then(res => {
            res.json().then(data => {
                setFormPending(false);
                setFormSubmitted(true);
            });
        })
    }

    return(
        <section className={`bg game ro-relative ${platform.embed ? "ro-aspect-video ro-w-full" : "ro-w-screen ro-h-screen"} ro-overflow-hidden`}>

            {/*<LanguageSwitcher bgColour="ro-bg-white" textColour="ro-text-strokeBlue"/>*/}

            <div className={`ro-w-full ro-h-full ro-flex ro-flex-col ro-items-center ro-absolute ro-justify-center ro-gap-5 xl:ro-gap-12 ro-z-40`}>
                <Timer ref={timer} className={`${loaded > 1 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 -ro-translate-y-4"} transition`} />

                <IconRow answers={answers} loaded={loaded} highlightCorrect={correctHighlighted} blue={true} />

                <div className="ro-relative">
                    <img src={completeBackground} alt="" className={`ro-absolute ro-w-full ro-scale-[1.85] ro-top-0 -ro-translate-y-[85%] ro-transition ${completed ? "ro-opacity-1" : "ro-opacity-0"}`} />
                    <div className={`ro-transition-all ${completed ? "ro-h-[55px] xl:ro-h-[72px]" : incorrectAnswer ? "-ro-mt-2 ro-h-[46px]" : "ro-h-[36px]"}`}>
                        {
                            completed ?
                            <h2 className={`ro-font-poppins ro-text-xl xl:ro-text-3xl ro-text-strokeBlue ro-font-semibold ro-transition ro-text-center xl:ro-mb-0 ${loaded > 3 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`}>
                                {t('global.correct')}!<br />
                                {currentPhase && currentPhase.type === "fast" ?
                                    <span dangerouslySetInnerHTML={ {__html: t('game.fastSignsFound', {interpolation: {escapeValue: false}})} } />
                                    :
                                    <span dangerouslySetInnerHTML={ {__html: t('game.beFastSignsFound', {interpolation: {escapeValue: false}})} } />
                                }
                            </h2>
                            : incorrectAnswer ?
                            <h2 className="ro-font-poppins ro-text-xl xl:ro-text-3xl ro-text-strokeBlue ro-font-semibold ro-transition ro-text-center">
                                <span className="ro-text-2xl xl:ro-text-4xl">+ {t('global.10secs')}<br /></span>
                                {t('global.almost')}!{' '}
                                {currentPhase && currentPhase.type === "fast" ?
                                    <span dangerouslySetInnerHTML={ {__html: t('game.keepTryingFast', {interpolation: {escapeValue: false}})} } />
                                    :
                                    <span dangerouslySetInnerHTML={ {__html: t('game.keepTryingBeFast', {interpolation: {escapeValue: false}})} } />
                                }
                            </h2>
                            :
                            <h2 className={`ro-font-poppins ro-text-xl ro-text-center xl:ro-text-3xl ro-text-strokeBlue ro-font-semibold ro-transition ${loaded > 3 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`}>
                                {currentPhase && currentPhase.type === "fast" ?
                                    <span dangerouslySetInnerHTML={ {__html: t('game.pressPhoneFast', {interpolation: {escapeValue: false}})} } />
                                    : currentPhase && currentPhase.type === "befast" ?
                                        <span dangerouslySetInnerHTML={ {__html: t('game.pressPhoneBeFast', {interpolation: {escapeValue: false}})} } />
                                        : <span dangerouslySetInnerHTML={ {__html: t('game.pressPhoneFast', {interpolation: {escapeValue: false}})} } />
                                }
                            </h2>
                        }
                    </div>

                </div>

                <div className="ro-relative">
                    {
                        activeGame ?
                            completed ?
                            <div className="ro-flex ro-flex-row ro-bg-strokeGreen ro-text-white ro-font-poppins ro-py-2 xl:ro-py-4 ro-mt-5 ro-px-4 xl:ro-px-8 ro-rounded-full ro-items-center ro-justify-center ro-font-semibold ro-text-md xl:ro-text-lg ro-gap-1 ro-shadow-md ro-cursor-pointer ro-transition-all hover:-ro-translate-y-1" onClick={toIntermission}>{t('global.continue')} <HiChevronRight className="ro-text-lg xl:ro-text-2xl" /></div>
                            :
                                <GreenButton className={`ro-transition ${incorrectAnswer ? "ro-bg-strokeRed ro-shadow-white/20 after:ro-border-strokeRed after:ro-w-[150px] after:ro-h-[150px]" : ""}`} singleRingHover onClick={submitAnswer}>
                                    <img src={incorrectAnswer ? x : phone} alt={`${incorrectAnswer ? "X icon" : "Phone icon"}`} className="ro-w-2/5" />
                                </GreenButton>
                        :
                        <GreenButton className={`ro-transition ${loaded > 4 ? "ro-opacity-1 ro-scale-100" : "ro-opacity-0 ro-scale-90"}`} singleRingHover onClick={startGame}>
                            <img src={greenArrow} alt="" className="ro-right-full -ro-top-3 ro-absolute xl:ro-mr-8 ro-scale-50 xl:ro-scale-100" />
                            <h3 className="ro-font-poppins ro-text-white ro-font-semibold ro-text-xl xl:ro-text-2xl">{t('global.start')}</h3>
                        </GreenButton>
                    }
                </div>
            </div>

            <div className={`ro-w-full ro-h-full bg intermission ro-absolute ro-z-50 ro-transition-all ro-duration-500 ro-flex ro-flex-col ro-items-center ro-justify-center ro-gap-2 xl:ro-gap-8 ${intermission === 0 ? "ro-opacity-0 -ro-translate-x-full ro-scale-75 ro-rounded-3xl" : intermission === 1 ? "ro-scale-75 ro-rounded-3xl ro-opacity-1 ro-translate-x-0" : intermission === 2 ? "ro-scale-100 ro-rounded-none ro-opacity-1 ro-translate-x-0" : intermission === 3 ? "ro-scale-75 ro-rounded-3xl ro-opacity-1 ro-translate-x-0" : "ro-scale-75 ro-rounded-3xl ro-opacity-0 ro-translate-x-full"}`}>
                <Timer time={time} white />

                <h1 className="ro-font-poppins ro-font-semibold ro-text-2xl xl:ro-text-7xl xl:ro-mt-2 ro-text-white ro-mb-8">
                    <span dangerouslySetInnerHTML={ {__html: t('game.soHowFastAreYou', {interpolation: {escapeValue: false}})} } />
                </h1>
                {fastStaticIcons}
                <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-md xl:ro-text-3xl ro-text-center ro-max-w-lg xl:ro-max-w-7xl ro-leading-tight ro-mt-2">
                    <span dangerouslySetInnerHTML={ {__html: t('game.signsGettingFaster', {interpolation: {escapeValue: false}})} } />
                </h2>
                <GreenButton onClick={startNextPhase} className="ro-w-16 ro-h-16 xl:ro-w-32 xl:ro-h-32">
                    <img src={whiteArrow} alt="" className="ro-w-8 ro-h-auto" />
                </GreenButton>
            </div>

            <div className={`ro-w-full ro-h-full ro-bg-strokeBlue ro-absolute ro-z-50 ro-transition-all ro-duration-500 ro-flex ro-flex-col ro-items-center ro-justify-center ro-gap-2 xl:ro-gap-8 ${intermission === -2 ? "ro-scale-75 ro-rounded-3xl ro-opacity-0 ro-translate-x-full" : intermission === 4 ? "ro-scale-75 ro-rounded-3xl ro-opacity-1 ro-translate-x-0" : intermission === 5 ? "ro-scale-100 ro-rounded-none ro-opacity-1 ro-translate-x-0" : intermission === 6 ? "ro-scale-75 ro-rounded-3xl ro-opacity-1 ro-translate-x-0" : "ro-scale-75 ro-rounded-3xl ro-opacity-0 -ro-translate-x-full"}`}>
                <Timer time={time} white />

                <h1 className="ro-font-poppins ro-font-semibold ro-text-2xl xl:ro-text-7xl xl:ro-mt-4 ro-text-white ro-text-center">
                    <span dangerouslySetInnerHTML={ {__html: t('game.dontJustThink', {interpolation: {escapeValue: false}})} } />
                </h1>
                <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-lg xl:ro-text-3xl ro-max-w-lg xl:ro-max-w-4xl ro-text-center ro-mb-5 xl:ro-mb-8">
                    <span dangerouslySetInnerHTML={ {__html: t('game.effectBalanceEyesight', {interpolation: {escapeValue: false}})} } />
                </h2>

                {befastStaticIcons}

                <GreenButton onClick={startNextPhase} className="ro-w-16 ro-h-16 xl:ro-w-32 xl:ro-h-32">
                    <img src={whiteArrow} alt="" className="ro-w-8 ro-h-auto" />
                </GreenButton>
            </div>

            <div className={`ro-w-full ro-h-full bg outro ro-absolute ro-z-50 ro-transition-all ro-duration-500 ro-flex ro-flex-col ro-items-center ro-justify-center ro-gap-2 xl:ro-gap-12 ${outro === 0 ? "ro-scale-75 ro-opacity-0 ro-rounded-3xl -ro-translate-x-full" : outro === 1 ? "ro-scale-75 ro-opacity-1 ro-rounded-3xl ro-translate-x-0" : outro === 2 ? "ro-scale-100 ro-opacity-1 ro-rounded-none ro-translate-x-0" : outro === 3 ? "ro-scale-75 ro-opacity-1 ro-rounded-3xl ro-translate-x-0" : "ro-scale-75 ro-opacity-0 ro-rounded-3xl ro-translate-x-full"}`}>
                <div className="ro-flex ro-flex-row xl:ro-flex-col ro-px-4 ro-gap-4">
                    <div className="ro-flex-1 ro-items-center ro-justify-center ro-gap-4 ro-flex ro-flex-col xl:ro-gap-0">
                        <h1 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-xl xl:ro-text-5xl ro-text-center xl:ro-mb-8">{t('global.wellDone')}! <span dangerouslySetInnerHTML={ {__html: t('game.yourScoreWas', {interpolation: {escapeValue: false}})} } /></h1>
                        <Timer time={time} white finalScore />

                        <div role="button" className="ro-bg-strokeGreen ro-flex ro-flex-row ro-items-center ro-text-white ro-font-poppins ro-font-semibold ro-text-lg xl:ro-text-xl ro-gap-4 ro-px-4 ro-py-2 xl:ro-p-5 ro-mt-2 ro-mb-4 xl:ro-mb-0 xl:ro-mt-8 ro-transition hover:ro-scale-105" onClick={() => setShareModal(true)}>
                            {t('global.share')} <img src={shareIcon} alt="" />
                        </div>
                    </div>
                    {
                        formSubmitted ?
                            <div className="ro-flex-1 ro-flex-col ro-flex ro-items-center ro-justify-center">
                                <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-lg xl:ro-text-4xl xl:ro-mt-8 xl:ro-mb-4">{t('game.scoreSubmitted')}</h2>
                                {
                                    // platform.embed ?
                                    //     <div className="bg-strokeGreen/70 backdrop-blur-md flex flex-row items-center text-white font-poppins font-semibold text-lg xl:text-xl gap-2 px-4 py-2 xl:p-5 mt-2 mb-4 xl:mb-0">
                                    //         Check out the leaderboard below <HiChevronDown />
                                    //     </div>
                                    // :
                                    //     <Link to="/leaderboard">
                                    //         <div role="button" className="bg-strokeGreen flex flex-row items-center text-white font-poppins font-semibold text-lg xl:text-xl gap-2 px-4 py-2 xl:p-5 mt-2 mb-4 xl:mb-0 transition hover:scale-105" onClick={() => setShareModal(true)}>
                                    //             View Leaderboard <IoTrophy />
                                    //         </div>
                                    //     </Link>
                                }

                                    <Link to="/leaderboard">
                                        <div role="button" className="ro-bg-strokeGreen ro-flex ro-flex-row ro-items-center ro-text-white ro-font-poppins ro-font-semibold ro-text-lg xl:ro-text-xl ro-gap-2 ro-px-4 ro-py-2 xl:ro-p-5 ro-mt-2 ro-mb-4 xl:ro-mb-0 ro-transition hover:ro-scale-105" onClick={() => setShareModal(true)}>
                                            {t('global.viewLeaderboard')} <IoTrophy />
                                        </div>
                                    </Link>
                            </div>
                        :
                            <div className="ro-flex-1 ro-flex-col ro-justify-center ro-items-center ro-flex xl:ro-mt-8">
                                <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-lg xl:ro-text-4xl">
                                    {t('game.submitYourScore')}
                                </h2>

                                <div className="ro-flex-col xl:ro-flex-row ro-gap-3 xl:ro-gap-8 ro-w-full xl:ro-max-w-5xl ro-flex xl:ro-mt-8 ro-items-end">
                                    <FormInput label={t('global.name')} required ref={nameField} />
                                    <div className="ro-flex ro-flex-row ro-gap-3 xl:ro-gap-8 ro-items-end">
                                        <div className={`ro-flex ro-flex-col xl:ro-gap-2 ro-flex-1`}>
                                            <div className="ro-font-poppins ro-font-semibold ro-text-white ro-text-sm xl:ro-text-xl ro-flex ro-flex-row ro-items-center ro-flex-nowrap ro-whitespace-nowrap ro-mb-[2px]">
                                                {t('global.country')}*
                                            </div>
                                            <select className="ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-h-8 xl:ro-h-14 ro-px-4 ro-py-0 ro-max-h-8 xl:ro-max-h-14 ro-max-w-[150px] ro-appearance-none" onChange={(e) => setCountryField(e.target.value)}>
                                                {
                                                    countries.sort((a, b) => {
                                                        if (a > b){
                                                            return 1;
                                                        }

                                                        if (b > a){
                                                            return -1;
                                                        }

                                                        return 0;
                                                    }).map((country, index) => {
                                                        return <option value={country}>{country}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <FormInput label={t('leaderBoard.leaderBoardNameLabel')} sublabel={t('leaderBoard.leaderBoardNameSubLabel')} required ref={lbNameField} className="ro-flex-0 ro-w-52" />
                                    </div>
                                    <FormInput label={t('leaderBoard.leaderBoardEmailLabel')} required long ref={emailField}/>
                                </div>

                                <div className="ro-flex ro-flex-row xl:ro-max-w-4xl ro-gap-5 ro-my-3 ro-items-center xl:ro-items-start xl:ro-mt-8">
                                    <div className="ro-w-4 ro-h-4 xl:ro-w-8 xl:ro-h-8 ro-bg-white ro-flex-none ro-relative ro-flex ro-items-center ro-justify-center">
                                        {checkbox ? <img src={blueCheck} alt="" className="ro-w-[10px] xl:ro-w-5 ro-h-[10px] xl:ro-h-5" /> : ""}
                                        <input type={"checkbox"} className="ro-absolute ro-opacity-0 ro-h-full ro-w-full ro-top-0 ro-left-0 ro-right-0 ro-bottom-0 ro-cursor-pointer" onClick={toggleCheckbox} />
                                    </div>
                                    <p className="ro-font-poppins ro-font-normal ro-text-white ro-text-sm xl:ro-text-md ro-flex-1">
                                        <span className="ro-font-bold" onClick={() => setPrivacyPolicy(true)}>{t('leaderBoard.leaderBoardPrivacyPolicy')}.*</span><br />
                                        <span className="ro-hidden xl:ro-block" dangerouslySetInnerHTML={ {__html: t('leaderBoard.leaderBoardDataStatement', {interpolation: {escapeValue: false}})} } />
                                    </p>
                                </div>

                                {
                                    formError != null ?
                                        <p className="ro-text-white ro-bg-red-500 ro-py-1 ro-px-3 xl:ro-px-6 xl:ro-py-2 ro-text-xs ro-mb-2 -ro-mt-1 xl:ro-mb-0 xl:ro-mt-0 xl:ro-text-base ro-rounded-full ro-font-poppins ro-font-normal">{formError}</p>
                                    : null
                                }

                                {
                                    formPending ?
                                        <div className="ro-bg-strokeGreen ro-flex ro-items-center ro-justify-center ro-h-11 ro-w-11 xl:ro-h-16 xl:ro-w-16 ro-rounded-full">
                                            <svg className="ro-animate-spin ro-h-5 ro-w-5 ro-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="ro-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path className="ro-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </div>
                                    :
                                    <div role="button" className="ro-bg-strokeGreen ro-flex ro-flex-row ro-items-center ro-text-white ro-font-poppins ro-font-semibold ro-text-lg xl:ro-text-xl ro-gap-4 ro-px-4 ro-py-2 xl:ro-p-5 xl:ro-mt-4 ro-transition hover:ro-scale-105" onClick={submitScore}>
                                        {t('leaderBoard.submitMyScore')}
                                        <img src={chevronRight} alt="" />
                                    </div>
                                }
                            </div>
                        }
                </div>
            </div>

            {
                shareModal ? <ShareModal time={time} hideModal={() => setShareModal(false)} /> : null
            }

            {
                privacyPolicy ? <PrivacyPolicyModal hideModal={() => setPrivacyPolicy(false)} /> : null
            }

            <div className="ro-w-full ro-h-full landscape:ro-hidden xl:ro-hidden ro-bg-strokeBlue ro-flex ro-flex-col ro-items-center ro-justify-center ro-gap-12 ro-z-50 ro-absolute">
                <h1 className="ro-font-poppins ro-text-white ro-font-semibold ro-text-3xl lg:ro-text-4xl xl:ro-text-5xl 2xl:ro-text-6xl ro-px-6 ro-text-center">{t('global.rotateDevice')}</h1>
            </div>
        </section>
    )
}

interface PrivacyPolicyProps {
    /**
     * @property {Function} hideModal Method to be called by component when closing self
     */
    hideModal: Function
}

function PrivacyPolicyModal(props: PrivacyPolicyProps){
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);

    const hide = () => {
        setVisible(false);
        setTimeout(() => {
            props.hideModal();
        }, 150);
    }

    return (
        <div className={`ro-absolute ro-w-full ro-h-full ro-top-0 ro-left-0 ro-right-0 ro-bottom-0 ro-flex ro-flex-col ro-items-center ro-justify-center ro-z-50 xl:ro-hidden ro-transition-all ${visible ? "ro-bg-strokeBlue/80 ro-backdrop-blur-lg ro-saturate-100" : "ro-bg-transparent"}`} onClick={hide}>
            <div className={`ro-bg-white ro-w-2/3 ro-px-6 ro-py-3 ro-rounded-xl ro-shadow-md lg:ro-max-w-3xl ro-transition-all ${visible ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-3"}`}>
                <h2 className="ro-font-poppins ro-text-xl ro-font-semibold ro-text-strokeBlue">Privacy Statement</h2>
                <p className="ro-font-poppins ro-text-sm ro-font-normal ro-text-strokeBlue/80 ro-mt-2">The data you submit here will only be used in relation to the Stroke Spotter. By submitting via this part of the website you are granting us permission to store and process your data as according to our <a href="https://www.world-stroke.org/privacy-policy" target="_blank" rel="noreferrer" className="ro-font-semibold ro-underline ro-bg-none ro-mb-0 ro-pb-0">Privacy Policy</a>. Your information will never be sold on to any third parties.</p>
            </div>
            <p className={`ro-font-poppins ro-text-xs ro-font-normal ro-text-white ro-mt-2 ro-uppercase ro-tracking-wider ro-transition-all ${visible ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 -ro-translate-y-3"}`}>Tap anywhere to close</p>
        </div>
    )
}

interface FormInputProps {
    /**
     * @property {string} label Text to be attached to the field as a label
     */
    label: string
    /**
     * @property {string} [sublabel] Text to appear under the label
     */
    sublabel?: string | null
    /**
     * @property {boolean} [required] Mark whether a field is required
     */
    required?: boolean
    /**
     * @property {boolean} [long] Toggle whether field will be styled to be longer than other sibling fields
     */
    long?: boolean,
    /**
     * @property {string} [className] Provide additional classes for component
     */
    className?: string
}

const FormInput = forwardRef((props: FormInputProps, ref) => {


    const [value, setValue] = useState("");

    useImperativeHandle(ref, () => ({
        getValue(){
            return value;
        }
    }));

    return(
        <div className={`ro-flex ro-flex-col xl:ro-gap-2 ro-flex-auto ${props.long ? "ro-w-full xl:ro-w-96" : "ro-w-full xl:ro-min-w-72"} ${props.className ? props.className : ""}`}>
            <div className="ro-font-poppins ro-font-semibold ro-text-white ro-text-sm xl:ro-text-l ro-flex ro-flex-col ro-flex-nowrap ro-whitespace-nowrap ro-items-start">
                <div className="ro-flex-row ro-flex">
                    {props.label}
                    {props.required ? "*" : ""}
                </div>
                {props.sublabel ? <span className="ro-font-normal ro-text-xs ro-mb-[2px]">({props.sublabel})</span> : ""}
            </div>
            <input type="text" className="ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-h-8 xl:ro-h-14 ro-px-4" onChange={(e) => {setValue(e.target.value)}} />
        </div>
    )
});

interface IconRowProps {
    /**
     * @property {boolean[]} answers Pass the current answers to be displayed
     */
    answers: boolean[],
    /**
     * @property {number} loaded Set the initial loaded phase
     */
    loaded: number,
    /**
     * @property {boolean} [highlightCorrect] Highlight any incorrect answers with a red border
     */
    highlightCorrect?: boolean,
    /**
     * @property {boolean} blue Toggle styling to use blue borders and lines
     */
    blue: boolean,
}

function IconRow(props: IconRowProps = {
    answers: [],
    loaded: 0,
    highlightCorrect: false,
    blue: true
}){

    const { t, i18n } = useTranslation(['translation']);

    const [difficulty, setDifficulty] = useState(false);

    const [allCorrect, setAllCorrect] = useState(false);

    const balance = useRef<any>();
    const eyesight = useRef<any>();
    // const face = useRef<any>();
    const arms = useRef<any>();
    const speech = useRef<any>();
    const time = useRef<any>();

    useEffect(() => {
        if (props.answers.length === 6){
            setDifficulty(true);
        } else {
            setDifficulty(false);
        }

        checkAllCorrect();
        // eslint-disable-next-line
    }, [props.answers]);

    const checkAllCorrect = () => {
        var correct = true;

        for (var i = 0; i < props.answers.length; i++){
            if (!props.answers[i]){
                correct = false;
            }
        }

        if (correct && !props.blue){
            setAllCorrect(true);
        } else {
            setAllCorrect(false);
        }
    }

    var firstIcon;
    var secondIcon;
    var thirdIcon;
    if( i18n.language == 'en'  ){
        firstIcon = <FaceIcon propAllCorrect={allCorrect} propDifficulty={difficulty} answers={props.answers} loaded={props.loaded} highlightCorrect={props.highlightCorrect} blue={props.blue}/> ;
        secondIcon = <ArmsIcon propAllCorrect={allCorrect} propDifficulty={difficulty} answers={props.answers} loaded={props.loaded} highlightCorrect={props.highlightCorrect} blue={props.blue}/>;
        thirdIcon = <SpeechIcon propAllCorrect={allCorrect} propDifficulty={difficulty} answers={props.answers} loaded={props.loaded} highlightCorrect={props.highlightCorrect} blue={props.blue}/>;
    }else{
        firstIcon = <SpeechIcon propAllCorrect={allCorrect} propDifficulty={difficulty} answers={props.answers} loaded={props.loaded} highlightCorrect={props.highlightCorrect} blue={props.blue}/>;
        secondIcon = <ArmsIcon propAllCorrect={allCorrect} propDifficulty={difficulty} answers={props.answers} loaded={props.loaded} highlightCorrect={props.highlightCorrect} blue={props.blue}/>;
        thirdIcon = <FaceIcon propAllCorrect={allCorrect} propDifficulty={difficulty} answers={props.answers} loaded={props.loaded} highlightCorrect={props.highlightCorrect} blue={props.blue}/> ;
    }

    return(
        <div className="ro-flex ro-flex-row ro-gap-0 ro-items-center">
            {
                // Balance
                difficulty ?
                <div className="ro-flex ro-flex-row ro-items-center">
                    {
                        allCorrect ?
                            <div className="ro-flex ro-flex-col ro-items-center">
                                <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{t('global.balance')}</span>
                                <Icon correct={props.answers[0]} type="balance" scope="all" blue={props.blue ? true : false} className={`ro-transition ro-shadow-lg ${props.loaded > 0 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={balance} answers={props.answers} highlighted={props.highlightCorrect} />
                            </div>
                        :
                            <Icon correct={props.answers[0]} type="balance" scope="all" blue={props.blue ? true : false} className={`ro-transition ro-shadow-lg ${props.loaded > 0 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={balance} answers={props.answers} highlighted={props.highlightCorrect} />
                    }

                    <ConnectLine blue={props.blue} className={`ro-shadow-md ${props.loaded > 2 ? "ro-opacity-1 ro-translate-x-0" : "ro-opacity-0 -ro-translate-x-14"}`} />
                </div>
                : null
            }

            {
                // Eyesight
                difficulty ?
                <div className="ro-flex ro-flex-row ro-items-center ro-mr-4 xl:ro-mr-16">
                    {
                        allCorrect ?
                            <div className="ro-flex ro-flex-col ro-items-center">
                                <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{t('global.eyesight')}</span>
                                <Icon correct={props.answers[1]} type="eyesight" scope="all" blue={props.blue} className={`ro-transition ro-shadow-lg ${props.loaded > 0 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={eyesight} answers={props.answers} highlighted={props.highlightCorrect} />
                            </div>
                        :
                            <Icon correct={props.answers[1]} type="eyesight" scope="all" blue={props.blue} className={`ro-transition ro-shadow-lg ${props.loaded > 0 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={eyesight} answers={props.answers} highlighted={props.highlightCorrect} />
                    }

                </div>
                : null
            }

            {firstIcon}
            {secondIcon}
            {thirdIcon}

            {/* Time */}
            <div className="ro-flex ro-flex-row ro-items-center">
                {
                    allCorrect ?
                        <div className="ro-flex ro-flex-col ro-items-center">
                            <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{t('global.time')}</span>
                            <Icon correct={difficulty ? props.answers[5] : props.answers[3]} type="time" scope="all" blue={props.blue} className={`ro-transition ro-shadow-lg ${props.loaded > 3 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={time} answers={props.answers} highlighted={props.highlightCorrect} />
                        </div>
                    :
                        <Icon correct={difficulty ? props.answers[5] : props.answers[3]} type="time" scope="all" blue={props.blue} className={`transition shadow-lg ${props.loaded > 3 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={time} answers={props.answers} highlighted={props.highlightCorrect} />
                }
            </div>
        </div>
    )
}

export default Game