/**
 * @typedef {"staging" | "local" | "production"} EnvType
 */

class Platform {
    /**
     * @property {EnvType} - Returns either "staging", "local" or "production" depending on the current environment
     */
    env: "staging" | "local" | "production";
    /**
     * @property {string} - Endpoint for the API based on the current environment. Will return null for local
     */
    endpoint: string | null;
    /**
     * @property {boolean} - Is the site currently running in an embed
     */
    embed: boolean
    /**
     * @property {string} - ExpressionEngine Action ID for the API endpoint 
     */
    actId: string

    constructor(){
        let url = window.location.href;
        let embedFind = "save-lives-be-fast";
        let embedFound = url.match(embedFind);

        if (embedFound !== null && embedFound[0] === embedFind){
            this.embed = true;
        } else {
            this.embed = false;
        }

        if (url.includes(".local") || url.includes("localhost")){
            // Set local config
            this.env = "local";
            this.endpoint = null;
            this.actId = "0";

            return;
        }

        if (url.includes(".optima-staging.co.uk") || url.includes(".optimastaging.co.uk")){
            // Set staging config
            this.env = "staging";
            this.endpoint = "https://wso.optima-staging.co.uk/?";
            this.actId = "112";

            return;
        }

        // Set production config
        this.env = "production";
        this.endpoint = "https://www.world-stroke.org/?";
        this.actId = "107";

        return;
    }
}

export { Platform }