
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useSearchParams } from "react-router-dom";

// Importing translation files

import translationEN from "./en/translation.json";
import translationSI from "./si/translation.json";


//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    si: {
        translation: translationSI,
    },
};

//i18N Initialization
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng:check_url_segments(), //default language
        interpolation: {
            escapeValue: false,
        },
    });

function check_url_segments(){
 const queryParameters = new URLSearchParams(window.location.search);
    const lang = queryParameters.get("lang")

    if( lang != null && lang != '' && lang == 'si' ){
        return lang;
    }else{
        return 'en';
    }
}

export default i18n;