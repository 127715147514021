import { useEffect, useState, forwardRef } from "react";

import BalanceCorrectIcon from "../assets/icons/correct/Balance.svg";
import BalanceIncorrectMohawk from "../assets/icons/incorrect/Mohawk.svg";
import BalanceIncorrectShocked from "../assets/icons/incorrect/Shocked.svg";
import BalanceIncorrectHeartEyes from "../assets/icons/incorrect/Heart Eyes.svg";

import EyesightCorrectIcon from "../assets/icons/correct/Eyesight.svg";
import EyesightIncorrectHearing from "../assets/icons/incorrect/Hearing.svg";
import EyesightIncorrectMedication from "../assets/icons/incorrect/Medication.svg";
import EyesightIncorrectLaughing from "../assets/icons/incorrect/Laughing.svg";

import FaceCorrectIcon from "../assets/icons/correct/Face.svg";
import FaceIncorrectWink from "../assets/icons/incorrect/Winking.svg";
import FaceIncorrectGlasses from "../assets/icons/incorrect/Glasses.svg";
import FaceIncorrectSad from "../assets/icons/incorrect/Sad.svg";

import ArmsCorrectIcon from "../assets/icons/correct/Arms.svg";
import ArmsIncorrectStrong from "../assets/icons/incorrect/Strong.svg";
import ArmsIncorrectWave from "../assets/icons/incorrect/Wave.svg";
import ArmsIncorrectOk from "../assets/icons/incorrect/OK.svg";

import SpeechCorrectIcon from "../assets/icons/correct/Speech.svg";
import SpeechIncorrectFever from "../assets/icons/incorrect/Fever.svg";
import SpeechIncorrectStern from "../assets/icons/incorrect/Stern.svg";
import SpeechIncorrectTongueOut from "../assets/icons/incorrect/Tongue out.svg";

import TimeCorrectIcon from "../assets/icons/correct/Time.svg";
import TimeIncorrectTaxi from "../assets/icons/incorrect/Taxi.svg";
import TimeIncorrectWait from "../assets/icons/incorrect/Wait.svg";
import TimeIncorrectSinging from "../assets/icons/incorrect/Singing.svg";

interface IconNewProps {
    /**
     * @param {string} [className] Pass additional classes to element
     */
    className?: string
    /**
     * @param {"balance" | "eyesight" | "face" | "arms" | "speech" | "time"} type Specify the icon type
     */
    type: "balance" | "eyesight" | "face" | "arms" | "speech" | "time"
    /**
     * @param {boolean} [correct] Display the correct value for the specified icon type
     */
    correct?: boolean,
    /**
     * @deprecated
     */
    scope?: "all" | "specified",
    /**
     * @param {boolean} [highlighted] Highlight icon as incorrect
     */
    highlighted?: boolean
    /**
     * @param {boolean} [blue] Modifies the styling to include blue borders
     */
    blue?: boolean
    /**
     * @param {boolean[]} [answers] Specify the answers that should be used for the icon
     */
    answers?: boolean[],
}

const Icon = forwardRef((props: IconNewProps, ref) => {
    const icons = {
        correct: {
            balance: BalanceCorrectIcon,
            eyesight: EyesightCorrectIcon,
            face: FaceCorrectIcon,
            arms: ArmsCorrectIcon,
            speech: SpeechCorrectIcon,
            time: TimeCorrectIcon
        },
        incorrect: {
            all: [BalanceIncorrectMohawk, BalanceIncorrectShocked, BalanceIncorrectHeartEyes, EyesightIncorrectHearing, EyesightIncorrectMedication, EyesightIncorrectLaughing, FaceIncorrectWink, FaceIncorrectGlasses, FaceIncorrectSad, ArmsIncorrectStrong, ArmsIncorrectWave, ArmsIncorrectOk, SpeechIncorrectFever, SpeechIncorrectStern, SpeechIncorrectTongueOut, TimeIncorrectTaxi, TimeIncorrectWait, TimeIncorrectSinging],
            balance: [BalanceIncorrectMohawk, BalanceIncorrectShocked, BalanceIncorrectHeartEyes],
            eyesight: [EyesightIncorrectHearing, EyesightIncorrectMedication, EyesightIncorrectLaughing],
            face: [FaceIncorrectWink, FaceIncorrectGlasses, FaceIncorrectSad],
            arms: [ArmsIncorrectStrong, ArmsIncorrectWave, ArmsIncorrectOk],
            speech: [SpeechIncorrectFever, SpeechIncorrectStern, SpeechIncorrectTongueOut],
            time: [TimeIncorrectTaxi, TimeIncorrectWait, TimeIncorrectSinging]
        }
    };

    const [prevIcon, setPrevIcon] = useState<string | null>(null);
    const [nextIcon, setNextIcon] = useState<string | null>(null);
    const [activeIcon, setActiveIcon] = useState<0 | 1>(0);
    const [transitioning, setTransitioning] = useState(false);

    useEffect(() => {
        if (prevIcon === null && nextIcon === null){
            if (props.correct){
                setPrevIcon(icons.correct[props.type]);
            } else {
                const iconSet = icons.incorrect.all;
                iconSet.sort(() => 0.5 - Math.random());

                setPrevIcon(iconSet[0]);
            }
        } else if (prevIcon !== null && nextIcon === null){
            if (props.correct){
                setNextIcon(icons.correct[props.type]);
                transition();
            } else {
                const iconSet = icons.incorrect.all;
                iconSet.sort(() => 0.5 - Math.random());

                setNextIcon(iconSet[0]);
                transition();
            }
        } else if (prevIcon !== null && nextIcon !== null){
            if (props.correct){
                if (activeIcon === 0){
                    setNextIcon(icons.correct[props.type]);
                    transition();
                } else {
                    setPrevIcon(icons.correct[props.type]);
                    transition();
                }
            } else {
                const iconSet = icons.incorrect.all;
                iconSet.sort(() => 0.5 - Math.random());

                if (activeIcon === 0){
                    setNextIcon(iconSet[0]);
                    transition();
                } else {
                    setPrevIcon(iconSet[0]);
                    transition();
                }
            }
        }
        // eslint-disable-next-line
    }, [props.answers]);

    const transition = () => {
        let transitionTime = 500;
        setTransitioning(true);

        setTimeout(() => {
            setTransitioning(false);

            if (activeIcon === 0){
                setActiveIcon(1);
            } else {
                setActiveIcon(0);
            }
        }, transitionTime);
    }

    return(
        <div className={`ro-bg-white ro-rounded-full ro-z-10 ro-border-2 ro-p-[2px] xl:ro-p-0 xl:ro-border-4 ro-relative ro-overflow-hidden ro-w-20 ro-h-20 xl:ro-w-36 xl:ro-h-36 ${props.highlighted && props.correct ? "ro-border-strokeGreen" : props.highlighted && !props.correct ? "ro-border-strokeRed" : props.blue ? "ro-border-strokeBlue" : "ro-border-white"} ${props.className ? props.className : ""}`}>
            <div className={`ro-absolute ro-w-24 ro-h-24 xl:ro-w-36 xl:ro-h-36 ro-transition ${transitioning && activeIcon === 0 ? "ro-opacity-0 ro-translate-y-full" : !transitioning && activeIcon === 1 ? "ro-opacity-0 -ro-translate-y-full" : "ro-opacity-1 ro-translate-y-0"}`}>
                {
                    prevIcon ?
                    <img src={prevIcon} alt="" className={`${prevIcon === BalanceIncorrectMohawk ? "ro-pl-2 ro-pt-1 ro-pb-8 ro-pr-8 xl:ro-pl-4 xl:ro-pt-2 xl:ro-pb-6 xl:ro-pr-6" : "ro-pl-2 ro-pt-2 ro-pb-8 ro-pr-8 xl:ro-pl-4 xl:ro-pt-4 xl:ro-pb-6 xl:ro-pr-6"}`} />
                    : ""
                }
            </div>

            <div className={`ro-absolute ro-w-24 ro-h-24 xl:ro-w-36 xl:ro-h-36 ro-transition ${transitioning && activeIcon === 1 ? "ro-opacity-0 ro-translate-y-full" : !transitioning && activeIcon === 0 ? "ro-opacity-0 -ro-translate-y-full" : "ro-opacity-1 ro-translate-y-0"}`}>
                {
                    nextIcon ?
                    <img src={nextIcon} alt="" className={`${nextIcon === BalanceIncorrectMohawk ? "ro-pl-2 ro-pt-1 ro-pb-8 ro-pr-8 xl:ro-pl-4 xl:ro-pt-2 xl:ro-pb-6 xl:ro-pr-6": "ro-pl-2 ro-pt-2 ro-pb-8 ro-pr-8 xl:ro-pl-4 xl:ro-pt-4 xl:ro-pb-6 xl:ro-pr-6"}`} />
                    : ""
                }
            </div>
        </div>
    )
});

export { Icon }