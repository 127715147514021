import React, {Component, PureComponent, useRef} from 'react';
import { render } from 'react-dom';
import {Icon} from "./Icons";
import { ConnectLine, EndArrow } from "../Views/Landing";
import {useTranslation} from "react-i18next";



interface armsIconProps {
    /**
     * @param {string} [className] Provide additional classes
     */
    propAllCorrect?: boolean,
    /**
     * @param {string} [className] Provide additional classes
     */
    propDifficulty?: boolean,
    /**
     * @property {boolean[]} answers Pass the current answers to be displayed
     */
    answers: boolean[],
    /**
     * @property {number} loaded Set the initial loaded phase
     */
    loaded: number,
    /**
     * @property {boolean} [highlightCorrect] Highlight any incorrect answers with a red border
     */
    highlightCorrect?: boolean,
    /**
     * @property {boolean} blue Toggle styling to use blue borders and lines
     */
    blue: boolean,
}

function ArmsIcon(props: armsIconProps){
    const arms = useRef<any>();

    const {t, i18n } = useTranslation(['translation']);

    return(
        <div className="ro-flex ro-flex-row ro-items-center">
            {
                props.propAllCorrect ?
                    <div className="ro-flex ro-flex-col ro-items-center">
                        <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{t('global.arms')}</span>
                        <Icon correct={props.propDifficulty ? props.answers[3] : props.answers[1]} type="arms" scope="all" blue={props.blue} className={`ro-transition ro-shadow-lg ${props.loaded > 1 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={arms} answers={props.answers} highlighted={props.highlightCorrect} />
                    </div>
                    :
                    <Icon correct={props.propDifficulty ? props.answers[3] : props.answers[1]} type="arms" scope="all" blue={props.blue} className={`ro-transition ro-shadow-lg ${props.loaded > 1 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={arms} answers={props.answers} highlighted={props.highlightCorrect} />
            }
            <ConnectLine blue={props.blue} className={`shadow-md ${props.loaded > 3 ? "ro-opacity-1 ro-translate-x-0" : "ro-opacity-0 -ro-translate-x-14"}`} />
        </div>

    )
}

export { ArmsIcon }