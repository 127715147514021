import { useEffect, useState } from "react";
import CountryFlag from "react-country-flag";
import CountryList from "country-list";
import { HiChevronLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Platform } from "../Components/Platform";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../Components/LanguageSwitcher";

interface LeaderboardEntry {
    /**
     * @property {number} id Auto-incremented ID for the leaderboard entry
     */
    id: number
    /**
     * @property {string} lbName Name of the player for the leaderboard entry
     */
    lbName: string
    /**
     * @property {string} name Name of player which will be displayed in demo for missing lbName value
     */
    name: string
    /**
     * @property {number} position Position on the leaderboard of the leaderboard entry
     */
    position: number
    /**
     * @property {string} country Country code for the leaderboard entry
     */
    country: string
    /**
     * @property {number} time Total score (in ms) for the leaderboard entry
     */
    time: number
}

function Leaderboard(){

    const { t, i18n } = useTranslation(['translation']);

    const [countryDisplay] = useState<"flag" | "code" | "name">("code");
    const [results, setResults] = useState<LeaderboardEntry[]>([]);
    const platform = new Platform();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const loadLeaderboard = (start?: number) => {
        if (platform.env === "local"){
            loadDemo();
            return;
        }

        setLoading(true);

        let apiUrl;

        if (start){
            apiUrl = platform.endpoint! + new URLSearchParams({
                "ACT": platform.actId,
                "action": "get",
                "start": start.toString()
            });
        } else {
            apiUrl = platform.endpoint! + new URLSearchParams({
                "ACT": platform.actId,
                "action": "get"
            });
        }


        fetch(apiUrl, {
            method: "get",
        }).then(res => {
            res.json().then(data => {
                const lbEntries: LeaderboardEntry[]= data.data;
                setResults(lbEntries);
                setPage(page + 1);
                setLoading(false);

                return;
            })
        })
    }

    const loadDemo = () => {
        setPage(1);

        const data: LeaderboardEntry[] = [
            {
                id: 1,
                lbName: "ryan",
                name: "ryan",
                position: 1,
                country: "GB",
                time: 332145
            },
            {
                id: 2,
                lbName: "ryan",
                name: "ryan",
                position: 1,
                country: "GB",
                time: 387146
            },
            {
                id: 4,
                lbName: "ryan",
                name: "ryan",
                position: 1,
                country: "IE",
                time: 415194
            },
            {
                id: 3,
                lbName: "ryan",
                name: "ryan",
                position: 1,
                country: "KR",
                time: 469131
            },
            {
                id: 5,
                lbName: "ryan",
                name: "ryan",
                position: 1,
                country: "KR",
                time: 479131
            },
            {
                id: 6,
                name: "ryan",
                lbName: "ryan",
                position: 1,
                country: "KR",
                time: 512131
            },
            {
                id: 7,
                lbName: "ryan",
                position: 1,
                name: "ryan",
                country: "KR",
                time: 574131
            },
        ];

        setResults(data);
    }

    useEffect(() => {
        loadLeaderboard();
        // eslint-disable-next-line
    }, []);

    const formatTime = (time: number) => {
        let remaining = time;

        let hours = "00";
        let minutes = "00";
        let seconds = "00";

        let h = Math.floor(remaining / 60000);

        if (h > 0){
            if (h < 10){
                hours = "0" + h;
            } else {
                hours = h.toString();
            }
        }

        remaining = remaining % 60000;

        let m = Math.floor(remaining / 1000);

        if (m > 0){
            if (m < 10){
                minutes = "0" + m;
            } else {
                minutes = m.toString();
            }
        }

        remaining = remaining % 1000;

        if (remaining > 0){
            if (remaining < 10){
                seconds = "0" + remaining;
            } else {
                seconds = remaining.toString();
            }
        }

        return "" + hours + ":" + minutes + ":" + seconds;
    }

    const loadMore = () => {
        const start = page * 10;

        loadLeaderboard(start);
    }

    return(
        <section className={`${platform.embed ? "ro-w-full ro-h-full" : "ro-w-full ro-h-screen"} bg leaderboard ro-flex ro-items-center ro-justify-center overflow-hidden`}>

            {/*<LanguageSwitcher bgColour="ro-bg-white" textColour="ro-text-strokeBlue"/>*/}

            <div className="ro-flex ro-flex-col ro-max-h-full ro-mt-4 xl:ro-pt-24">
                <h1 className="ro-font-poppins ro-font-semibold ro-text-3xl xl:ro-text-6xl ro-text-white ro-text-center ro-max-w-2xl">{t('leaderBoard.title')}</h1>
                <div className="ro-flex ro-flex-row">
                    <Link to="/" className="ro-no-underline hover:ro-no-underline">
                        <div className="ro-flex ro-flex-row ro-items-center ro-text-white ro-font-poppins ro-text-xs xl:ro-text-lg ro-mb-2 ro-mt-1 xl:ro-mb-6 xl:ro-mt-2" role="button"><HiChevronLeft />
                            <span className="ro-relative ro-pb-[1px] after:ro-content-[''] after:ro-right-0 after:ro-absolute after:ro-top-full after:-ro-translate-y-[1px] after:ro-h-[1px] after:ro-w-full after:ro-bg-white after:ro-translate-x-full ro-overflow-hidden after:ro-opacity-0 hover:after:ro-opacity-100 after:ro-transition-all hover:after:ro-translate-x-0">
                                {t('leaderBoard.backToGame')}
                            </span>
                        </div>
                    </Link>
                </div>
                <div className={`ro-relative ro-max-h-full ro-flex-1 ${platform.embed ? "ro-overflow-hidden" : "ro-overflow-y-auto ro-overflow-x-hidden"} ro-rounded-t-[18px] xl:ro-rounded-t-[28px]`}>
                    <div className="ro-relative ro-flex ro-flex-col ro-flex-1 ro-gap-2 xl:ro-gap-3 ro-pb-4 xl:ro-pb-24">
                        <div className="ro-sticky ro-flex ro-flex-row ro-bg-strokeGreen ro-gap-4 ro-rounded-full ro-w-full ro-h-9 xl:ro-h-14 ro-items-center ro-justify-start ro-px-4 xl:ro-px-8 ro-top-0 ro-left-0 ro-z-[2] ro-border-[1px] xl:ro-border-2 ro-border-strokeGreen">
                            <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-xs xl:ro-text-lg ro-flex-[1]">{t('leaderBoard.pos')}</h2>
                            <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-xs xl:ro-text-lg ro-flex-[2]">{t('leaderBoard.country')}</h2>
                            <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-xs xl:ro-text-lg ro-flex-[4]">{t('leaderBoard.leaderBoardName')}</h2>
                            <h2 className="ro-font-poppins ro-font-semibold ro-text-white ro-text-xs xl:ro-text-lg ro-flex-[2]">{t('global.time')}</h2>
                        </div>

                        {
                            results.map((result, index) => {
                                return (
                                    <div className={`ro-flex ro-flex-row ro-bg-strokeBlueRich ro-w-full ro-h-9 xl:ro-h-14 ro-rounded-full ro-items-center ro-justify-start ro-px-4 xl:ro-px-8 ro-gap-4 ${index === 0 ? "ro-border-strokeGold" : index === 1 ? "ro-border-strokeSilver" : index === 2 ? "ro-border-strokeBronze" : "ro-border-strokeBlueRich"} ro-border-[1px] xl:ro-border-2 ro-box-border`}>
                                        <div className="ro-flex-[1]">
                                            <p className={`ro-font-fira ro-w-6 xl:ro-w-9 ro-h-6 xl:ro-h-9 ro-flex ro-text-xs xl:ro-text-base ro-items-center ro-justify-center ro-rounded-full ro-font-bold ${index < 3 ? "ro-text-black" : "ro-text-strokeBlue"} ro-text-lg ${index === 0 ? "ro-bg-strokeGold" : index === 1 ? "ro-bg-strokeSilver" : index === 2 ? "ro-bg-strokeBronze" : "ro-bg-strokeGrey"}`}>{index + 1}</p>
                                        </div>
                                        {
                                            countryDisplay === "flag" ?
                                                <div className="ro-flex-[2]">
                                                    <CountryFlag countryCode={result.country} svg className="ro-text-xs ro-xl:text-2xl" />
                                                </div>
                                            : countryDisplay === "code" ?
                                                <p className="ro-font-poppins ro-font-normal ro-text-white ro-text-sm xl:ro-text-lg ro-flex-[2] ro-whitespace-nowrap ro-overflow-hidden ro-text-ellipsis">
                                                    {result.country}
                                                </p>
                                            : countryDisplay === "name" ?
                                                <p className="ro-font-poppins ro-font-normal ro-text-white ro-text-sm xl:ro-text-lg ro-flex-[2] ro-whitespace-nowrap ro-overflow-hidden ro-text-ellipsis">
                                                    {CountryList.getName(result.country)}
                                                </p>
                                            : <div className="ro-flex-[1]"></div>
                                        }
                                        <p className="ro-font-poppins ro-font-normal ro-text-white ro-text-xs xl:ro-text-lg ro-flex-[4]">{result.lbName === "" ? result.name : result.lbName}</p>
                                        <p className="ro-font-poppins ro-font-normal ro-text-white ro-text-xs xl:ro-text-lg ro-flex-[2]">{formatTime(result.time)}</p>
                                    </div>
                                )
                            })
                        }

                        {
                            results.length >= (10 * page) ?
                                <button className="ro-bg-strokeGreen ro-text-white ro-font-poppins ro-font-semibold ro-py-3 ro-rounded-full ro-mt-4 ro-flex ro-items-center ro-justify-center ro-transition-all hover:ro-scale-95" onClick={loadMore}>
                                    {
                                        loading ?
                                            <svg className="ro-animate-spin ro-h-5 ro-w-5 ro-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="ro-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path className="ro-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        : <span dangerouslySetInnerHTML={ {__html: t('global.loadMore', {interpolation: {escapeValue: false}})} } />
                                    }
                                </button>
                            : null
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Leaderboard