import { MouseEventHandler } from "react"

interface GreenButtonProps {
    /**
     * @param {JSX.Element | JSX.Element[]} [children] Pass any children to be displayed within the button
     */
    children?: JSX.Element | JSX.Element[],
    /**
     * @param {string} [className] Provide additional classes
     */
    className?: string,
    /**
     * @param {MouseEventHandler} [onClick] Pass through a click handler
     */
    onClick?: MouseEventHandler,
    /**
     * @param {boolean} [singleRingHover] Change styling to include just one ring on hover
     */
    singleRingHover?: boolean
}

function GreenButton(props: GreenButtonProps){
    return(
        <div role="button" className={`ro-w-20 ro-h-20 xl:ro-w-32 xl:ro-h-32 green-shadow-inner ro-bg-strokeGreen ro-rounded-full ro-shadow-strokeGreenShadow/60 ro-flex ro-justify-center ro-items-center before:ro-content-[""] ro-relative before:ro-absolute before:ro-w-20 before:ro-h-20 xl:ro-before:h-32 xl:ro-before:w-32 before:ro-bg-transparent before:ro-rounded-full before:ro-border-[5px] before:ro-border-strokeGreen/20 after:ro-content-[""] after:ro-absolute after:ro-w-10 after:ro-h-10 xl:after:ro-w-[106px] xl:after:ro-h-[106px] after:ro-rounded-full after:ro-bg-transparent after:ro-border-[5px] after:ro-border-strokeGreen before:ro-transition-all xl:hover:before:ro-w-[172px] xl:hover:before:ro-h-[172px] xl:hover:after:ro-w-[150px] xl:hover:after:ro-h-[150px] before:ro-z-10 after:ro-z-20 before:ro-hidden after:ro-hidden xl:before:ro-flex xl:after:ro-flex after:ro-transition-all ${props.className ? props.className : ""} ${props.singleRingHover ? "before:ro-hidden after:ro-border-strokeGreen/20" : ""}`} onClick={props.onClick ? props.onClick : () => {}}>
            {props.children}
        </div>
    )
}

export default GreenButton