import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

interface TimerProps {
    /**
     * @param {string} className Provide additional class styling
     */
    className?: string,
    /**
     * @param {number} [time] A default time for the timer to display
     */
    time?: number,
    /**
     * @param {boolean} [white] Alter display to better fit a white background
     */
    white?: boolean,
    /**
     * @param {boolean} [finalScore] Disable number animations
     */
    finalScore?: boolean
}

export interface TimerMethods {
    /**
     * @function
     * @name start
     * @description Start the timer
     */
    start: () => void
    /**
     * @function
     * @name stop
     * @description Stop the timer
     */
    stop: () => void
    /**
     * @function
     * @name clear
     * @description Reset the timer
     */
    clear: () => void
    /**
     * @function
     * @name isRunning
     * @description Returns boolean showing if the timer is currently running
     */
    isRunning: () => boolean
    /**
     * @function
     * @name addTime
     * @description Add additional time to the timer
     */
    addTime: (time: number) => void
    /**
     * @function
     * @name getTime
     * @description Get the time that is currently displayed on the timer
     */
    getTime: () => number
}

const Timer = forwardRef<TimerMethods, TimerProps>((props, ref) => {
    const [total, setTotal] = useState(0);
    const [hours, setHours] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");

    const [penalty, setPenalty] = useState(0);

    const [running, setRunning] = useState(false);

    useImperativeHandle(ref, () => ({
        start(){
            start();
        },
        stop(){
            stop();
        },
        clear(){
            clear();
        },
        isRunning(){
            return running;
        },
        addTime(time: number){
            addTime(time);
        },
        getTime(){
            return total + penalty;
        }
    }));

    useEffect(() => {
        if (props.time && props.time > 0){
            setTotal(props.time);
        }
    }, [props.time]);

    const start = () => {
        setRunning(true);
    }

    const stop = () => {
        setRunning(false);
        setTotal(total + penalty);
    }

    const clear = () => {
        setRunning(false);
        setTotal(0);
        setHours("00");
        setMinutes("00");
        setSeconds("00");
    }

    const addTime = (time: number) => {
        if (penalty === 0){
            setPenalty(time * 1000);
        } else {
            setPenalty(penalty + (time * 1000));
        }
    }

    const updateTime = (pen: number = 0) => {
        if (running){
            setTotal(total + 25);
        }

        let remaining = total;

        let h = Math.floor(remaining / 60000);
        
        if (h > 0){
            if (h < 10){
                setHours("0" + h);
            } else {
                setHours(h.toString());
            }
        }

        remaining = remaining % 60000;

        let m = Math.floor(remaining / 1000);

        if (m > 0){
            if (m < 10){
                setMinutes("0" + m);
            } else {
                setMinutes(m.toString());
            }
        } else {
            setMinutes("00");
        }

        remaining = remaining % 1000;

        if (remaining > 0){
            if (remaining < 10){
                setSeconds("0" + remaining);
            } else {
                setSeconds(remaining.toString());
            }
        } else {
            setSeconds("00");
        }

        if (penalty > 0 && !running){
            setPenalty(0);
        }
    }

    useEffect(() => {
        if (running){
            const timeout = setTimeout(updateTime, total === 0 ? 0 : 23); // 23 instead of 25 to account for the delay between catching change with useEffect to run again

            return () => {
                clearTimeout(timeout);
            }
        } else {
            updateTime();
        }
        // eslint-disable-next-line
    }, [total, running]);

    return(
        <div className={`ro-flex ro-flex-col ${props.finalScore ? "ro-items-center" : "ro-items-end"} ro-relative ro-z-10 ro-mb-2 xl:ro-mb-0`}>
            <h1 className={`ro-font-poppins ro-text-strokeRed ro-text-lg xl:ro-text-3xl ro-absolute ro-top-full xl:ro-top-auto xl:ro-bottom-full ro-mb-0 xl:ro-mb-2 ro-transition ${penalty > 0 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 -ro-translate-y-4 xl:ro-translate-y-4"}`}>
                +{Math.floor(penalty / 60000) < 10 ? "0" : ""}{Math.floor(penalty / 60000)}:{Math.floor((penalty % 60000) / 1000) < 10 ? "0" : ""}{Math.floor((penalty % 60000) / 1000)}:00
            </h1>
            <div className={`ro-flex ro-flex-row ro-gap-1 xl:ro-gap-3 ro-items-center ${props.className ? props.className : ""}`} aria-live="polite" aria-label={`Timer: ${hours} hours, ${minutes} minutes, ${seconds} seconds`}>
                <TimerNumber displayNumber={hours[0]} animate />
                <TimerNumber displayNumber={hours[1]} animate />

                <h1 aria-hidden="true" className={`ro-font-poppins ${props.white ? "ro-text-white ro-opacity-70" : "ro-text-strokeGreen"} ro-text-4xl xl:ro-text-8xl ro-mx-1`}>:</h1>

                <TimerNumber displayNumber={minutes[0]} animate />
                <TimerNumber displayNumber={minutes[1]} animate />

                <h1 aria-hidden="true" className={`ro-font-poppins ${props.white ? "ro-text-white ro-opacity-70" : "ro-text-strokeGreen"} ro-text-4xl xl:ro-text-8xl ro-mx-1`}>:</h1>

                <TimerNumber displayNumber={seconds[0]} />
                <TimerNumber displayNumber={seconds[1]} />
            </div>
        </div>
    )
});

interface TimerNumberProps {
    displayNumber: string
    animate?: boolean
}

function TimerNumber(props: TimerNumberProps){
    const [activeNum, setActiveNum] = useState<0 | 1>(0);
    const [transitioning, setTransitioning] = useState(false);
    const [prevNum, setPrevNum] = useState<string | null>(null);
    const [nextNum, setNextNum] = useState<string | null>(null);

    useEffect(() => {
        if (props.animate){
            if (prevNum === null && nextNum === null){
                setPrevNum(props.displayNumber);
            } else if (prevNum !== null && nextNum === null){
                setNextNum(props.displayNumber);
                transition();
            } else if (prevNum !== null && nextNum !== null){
                if (activeNum === 0){
                    setNextNum(props.displayNumber);
                    transition();
                } else {
                    setPrevNum(props.displayNumber);
                    transition();
                }
            }
        }
        // eslint-disable-next-line
    }, [props.displayNumber]);

    const transition = () => {
        let transitionTime = 500;
        setTransitioning(true);

        setTimeout(() => {
            setTransitioning(false);

            if (activeNum === 0){
                setActiveNum(1);
            } else {
                setActiveNum(0);
            }
        }, transitionTime);
    }

    return(
        props.animate ?
        <div className={`ro-w-8 ro-h-12 xl:ro-w-20 xl:ro-h-[120px] ro-shadow-sm ro-bg-white ro-rounded-md ro-relative ro-items-center ro-justify-center before:ro-content-[""] after:ro-content-[""] ro-flex ro-flex-col before:ro-relative after:ro-relative before:ro-z-0 after:ro-z-0 before:ro-w-8 xl:before:ro-w-20 before:ro-h-6 xl:before:ro-h-[60px] before:ro-rounded-t-md before:ro-border-x-[1px] xl:before:ro-border-x-2 before:ro-border-t-[1px] xl:before:ro-border-t-2 before:ro-border-strokeTimerBorder/20 before:ro-bg-white after:ro-bg-strokeOffWhite/50 after:ro-w-8 xl:after:ro-w-20 after:ro-h-6 xl:after:ro-h-[60px] after:ro-rounded-b-md after:ro-border-x-[1px] xl:after:ro-border-x-2 after:ro-border-b-[1px] xl:after:ro-border-b-2 after:ro-border-strokeTimerBorder/10`} aria-hidden="true">
            <div className={`ro-absolute ro-z-10 ro-font-poppins ro-text-strokeBlue ro-font-semibold ro-text-3xl xl:ro-text-7xl ro-transition ${transitioning && activeNum === 0 ? "ro-opacity-0 ro-translate-y-6" : !transitioning && activeNum === 1 ? "ro-opacity-0 -ro-translate-y-6" : "ro-opacity-1 ro-translate-y-0"}`}>{prevNum}</div>
            <div className={`ro-absolute ro-z-10 ro-font-poppins ro-text-strokeBlue ro-font-semibold ro-text-3xl xl:ro-text-7xl ro-transition ${transitioning && activeNum === 1 ? "ro-opacity-0 ro-translate-y-6" : !transitioning && activeNum === 0 ? "ro-opacity-0 -ro-translate-y-6" : "ro-opacity-1 ro-translate-y-0"}`}>{nextNum}</div>
        </div>
        :
        <div className={`ro-w-8 ro-h-12 xl:ro-w-20 xl:ro-h-[120px] ro-shadow-sm ro-bg-white ro-rounded-md ro-relative ro-items-center ro-justify-center before:ro-content-[""] after:ro-content-[""] ro-flex ro-flex-col before:ro-relative after:ro-relative before:ro-z-0 after:ro-z-0 before:ro-w-8 xl:before:ro-w-20 before:ro-h-6 xl:before:ro-h-[60px] before:ro-rounded-t-md before:ro-border-x-[1px] xl:before:ro-border-x-2 before:ro-border-t-[1px] xl:before:ro-border-t-2 before:ro-border-strokeTimerBorder/20 before:ro-bg-white after:ro-bg-strokeOffWhite/50 after:ro-w-8 xl:after:ro-w-20 after:ro-h-6 xl:after:ro-h-[60px] after:ro-rounded-b-md ro-border-x-[1px] xl:after:ro-border-x-2 after:ro-border-b-[1px] xl:after:ro-border-b-2 after:ro-border-strokeTimerBorder/10`} aria-hidden="true">
            <div className={`ro-absolute ro-z-10 ro-font-poppins ro-text-strokeBlue ro-font-semibold ro-text-3xl xl:ro-text-7xl`}>{props.displayNumber}</div>
        </div>
    )
}

export default Timer