import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";

import FacebookShare from "../assets/share/facebook.jpg";
import InstagramShare from "../assets/share/instagram.jpg";
import TwitterShare from "../assets/share/twitter.jpg";

import SKFacebookShare from "../assets/share/sk-facebook.png";
import SKInstagramShare from "../assets/share/sk-instagram.png";
import SKTwitterShare from "../assets/share/sk-twitter.png";

import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import {useTranslation} from "react-i18next";
import i18n from "i18next";


interface PlatformSettings {
    /**
     * @property {string | null} name Name of the social platform
     */
    name: string | null
    /**
     * @property {number} width Width of the generated image
     */
    width: number
    /**
     * @property {number} height Height of the generated image
     */
    height: number
    /**
     * @property {string} [image] Source or import of the base image
     */
    image?: string,
    /**
     * @property {TimePosition[]} timePos Position to place each digit within the image canvas
     */
    timePos: TimePosition[],
    /**
     * @property {string} fontSize Font size for the dynamic text
     */
    fontSize: string
}

interface TimePosition {
    /**
     * @property {number} x Position of the character on the x-axis
     */
    x: number
    /**
     * @property {number} y Position of the character on the y-axis
     */
    y: number
}

interface ShareProps {
    /**
     * @property {"facebook" | "twitter" | "instagram"} type Specify which social platform to use
     */
    type: "facebook" | "twitter" | "instagram"
    /**
     * @property {number} time Dynamic time value to be displayed in the image
     */
    time: number
}

const Share = forwardRef((props: ShareProps, ref) => {
    const [platform, setPlatform] = useState<PlatformSettings>({
        name: null,
        width: 0,
        height: 0,
        timePos: [],
        fontSize: ""
    });
    const [time, setTime] = useState<string>("");
    const [image, setImage] = useState<string>("");

    useImperativeHandle(ref, () => ({
        getImage(){
            return getImage();
        }
    }));

    const getImage = () => {
        return image;
    }

    const selectPlatform = () => {
        const type = props.type;
        if (type === "facebook"){


            if(i18n.language == 'en'){
               var fbimage = FacebookShare;
            }else{
                var fbimage = SKFacebookShare;
            }


            setPlatform({
                name: "Facebook",
                width: 1200,
                height: 630,
                image: fbimage,
                timePos: [
                    {
                        x: 372,
                        y: 339
                    },
                    {
                        x: 447,
                        y: 339
                    },
                    {
                        x: 563,
                        y: 339
                    },
                    {
                        x: 638,
                        y: 339
                    },
                    {
                        x: 754,
                        y: 339
                    },
                    {
                        x: 829,
                        y: 339
                    }
                ],
                fontSize: "64px"
            });
        }

        if (type === "instagram"){

            if(i18n.language == 'en'){
                var instaimage = InstagramShare;
            }else{
                var instaimage = SKInstagramShare;
            }

            setPlatform({
                name: "Instagram",
                width: 1080,
                height: 1080,
                image: instaimage,
                timePos: [
                    {
                        x: 219,
                        y: 495
                    },
                    {
                        x: 317,
                        y: 495
                    },
                    {
                        x: 470,
                        y: 495
                    },
                    {
                        x: 569,
                        y: 495
                    },
                    {
                        x: 722,
                        y: 495
                    },
                    {
                        x: 820,
                        y: 495
                    }
                ],
                fontSize: "80px"
            });
        }

        if (type === "twitter"){


            if(i18n.language == 'en'){
                var twitterimage = TwitterShare;
            }else{
                var twitterimage = SKTwitterShare;
            }

            setPlatform({
                name: "Twitter",
                width: 1024,
                height: 512,
                image: twitterimage,
                timePos: [
                    {
                        x: 318,
                        y: 266
                    },
                    {
                        x: 382,
                        y: 266
                    },
                    {
                        x: 481,
                        y: 266
                    },
                    {
                        x: 544,
                        y: 266
                    },
                    {
                        x: 644,
                        y: 266
                    },
                    {
                        x: 708,
                        y: 266
                    }
                ],
                fontSize: "56px"
            });
        }
    }

    const loadImage = () => {
        var c: HTMLCanvasElement = document.getElementById("shareCanvas") as HTMLCanvasElement;
        var ctx: CanvasRenderingContext2D = c.getContext("2d") as CanvasRenderingContext2D;

        const img: HTMLImageElement = new Image();

        img.addEventListener("load", () => {
            const factor = Math.min(c.width / img.width, c.height / img.height);
            ctx.scale(factor, factor);
            ctx.drawImage(img, 0, 0);
            ctx.scale(1 / factor, 1 / factor);

            renderTime();
        }, false);

        img.src = platform.image!;
    }

    const calcTime = () => {
        let remaining: number = props.time;
        var hours = "00";
        var minutes = "00";
        var seconds = "00";

        let h = Math.floor(remaining / 60000);

        if (h > 0){
            if (h < 10){
                hours = "0" + h;
            } else {
                hours = h.toString();
            }
        }

        remaining = remaining % 60000;

        let m = Math.floor(remaining / 1000);

        if (m > 0){
            if (m < 10){
                minutes = "0" + m;
            } else {
                minutes = m.toString();
            }
        }

        remaining = remaining % 1000;

        if (remaining > 0){
            if (remaining < 10){
                seconds = "0" + remaining;
            } else {
                seconds = remaining.toString();
            }
        }

        const total = hours + minutes + seconds;

        setTime(total);
    }

    const renderTime = () => {
        var c: HTMLCanvasElement = document.getElementById("shareCanvas") as HTMLCanvasElement;
        var ctx: CanvasRenderingContext2D = c.getContext("2d") as CanvasRenderingContext2D;

        ctx.font = `600 ${platform.fontSize} Poppins`;
        ctx.fillStyle = "#00378B";
        ctx.textAlign = "center"

        platform.timePos.forEach((pos, index) => {
            ctx.fillText(time[index], pos.x, pos.y)
        });

        const dataUrl = c.toDataURL("png");

        setImage(dataUrl);
    }

    useEffect(() => {
        selectPlatform();
        calcTime();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setImage("");
        selectPlatform();
        calcTime();
        // eslint-disable-next-line
    }, [props.type, props.time])

    useEffect(() => {
        loadImage();
        // eslint-disable-next-line
    }, [platform]);

    return(
        <div id="parent">
            <canvas id="shareCanvas" width={platform.width} height={platform.height} style={{cursor: "pointer", display: "none"}}></canvas>
            <img src={image} alt="" id="downloadImage" />
        </div>
    )
});

interface ShareModalProps {
    /**
     * @property {number} time Dynamic time value to be used in the image
     */
    time: number,
    /**
     * @property {Function} hideModal Method to be called when closing self
     */
    hideModal: Function
}

function ShareModal(props: ShareModalProps){

    const { t, i18n } = useTranslation(['translation']);

    const [visible, setVisible] = useState(false);
    const [selectedTab, setSelectedTab] = useState<"facebook" | "twitter" | "instagram">("facebook");

    const image = useRef<any>();

    const hide = () => {
        setVisible(false);
        setTimeout(() => {
            props.hideModal();
        }, 300)
    }

    const downloadImage = () => {
        const image = document.getElementById("downloadImage")
        const url = image?.getAttribute("src");

        if (url){
            const link = document.createElement("a");
            link.href = url;
            link.download = "My-FAST-Score.png";
            link.click();
        }
    }

    useEffect(() => {
        setVisible(true);
    }, []);

    return(
        <div className="ro-absolute ro-flex ro-items-center ro-justify-center ro-z-50 ro-w-full ro-h-full">
            <div className={`ro-bg-strokeBlue/70 ro-backdrop-blur-lg ro-absolute ro-w-full ro-h-full ro-left-0 ro-right-0 ro-top-0 ro-bottom-0 ro-transition-opacity ro-hidden xl:ro-flex ${visible ? "ro-opacity-1" : "ro-opacity-0"}`} onClick={hide}></div>
            <div className={`ro-relative ro-flex ro-flex-col ro-bg-white ro-transition-all ro-w-full ro-h-full xl:ro-h-auto xl:ro-w-[1000px] ro-shadow-lg ${visible ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`}>
                <div className="ro-absolute ro-right-0 ro-h-6 ro-w-6 -ro-top-7 ro-cursor-pointer ro-hidden xl:ro-flex" onClick={hide}>
                    <HiX size={24} color="white" />
                </div>
                <div className="ro-flex ro-flex-row ro-h-16">
                    <div className={`ro-flex ro-flex-1 ro-items-center ro-justify-center ro-font-semibold ro-h-full ro-gap-1 ro-font-poppins ro-text-strokeBlue ro-cursor-pointer ro-border-r-[1px] ${selectedTab === "twitter" ? "ro-border-r-gray-300" : "ro-border-r-gray-200"} ${selectedTab === "facebook" ? "ro-bg-white" : "ro-bg-gray-200"}`} onClick={() => setSelectedTab("facebook")}>
                        <FaFacebookF size={14} />
                        Facebook
                    </div>
                    <div className={`ro-flex ro-flex-1 ro-items-center ro-justify-center ro-font-semibold ro-h-full ro-gap-1 ro-font-poppins ro-text-strokeBlue ro-cursor-pointer ro-border-r-[1px] ${selectedTab === "facebook" ? "ro-border-r-gray-300" : "ro-border-r-gray-200"} ${selectedTab === "instagram" ? "ro-bg-white" : "ro-bg-gray-200"}`} onClick={() => setSelectedTab("instagram")}>
                        <FaInstagram size={18} />
                        Instagram
                    </div>
                    <div className={`ro-flex ro-flex-1 ro-items-center ro-justify-center ro-font-semibold ro-h-full ro-gap-1 ro-font-poppins ro-text-strokeBlue ro-cursor-pointer ${selectedTab === "twitter" ? "ro-bg-white" : "ro-bg-gray-200"}`} onClick={() => setSelectedTab("twitter")}>
                        <FaTwitter size={16} />
                        Twitter
                    </div>
                    <div className={`ro-flex ro-w-16 ro-h-16 ro-items-center ro-justify-center xl:ro-hidden ro-bg-gray-200 ro-border-l-[1px] ${selectedTab === "twitter" ? "ro-border-l-gray-200" : "ro-border-l-gray-300"}`} onClick={hide}>
                        <HiX size={24} color="#00378B" />
                    </div>
                </div>
                <div className="ro-flex ro-flex-row ro-px-5 ro-py-3 xl:ro-px-24 xl:ro-py-20 ro-w-full">
                    <div className="ro-flex ro-flex-row ro-w-full ro-h-full ro-items-center">
                        {
                            selectedTab === "facebook" ?
                                <div className="ro-flex ro-flex-[2] ro-flex-col ro-gap-6">

                                    <h2 className="ro-font-poppins ro-text-3xl ro-text-strokeBlue ro-font-semibold">{t('share.shareTimeFacebook')}</h2>
                                    <p className="ro-font-poppins ro-text-xl ro-text-strokeBlue ro-w-2/3">{t('share.downloadForFacebook')}</p>
                                    <div className="ro-flex ro-flex-row ro-gap-6 ro-items-center">
                                        {
                                            image.current ?
                                                <div onClick={downloadImage} role="button" className="ro-bg-strokeGreen ro-px-6 ro-py-3 ro-text-white ro-font-poppins ro-font-semibold ro-cursor-pointer ro-transition-all hover:ro-scale-105">{t('share.downloadingImage')}</div>
                                            :
                                                <div className="ro-bg-strokeGreen ro-px-6 ro-py-3 ro-text-white ro-font-poppins ro-font-semibold">Creating your image</div>
                                        }
                                        <a href="https://facebook.com" target="_blank" rel="noreferrer" className="ro-font-poppins ro-text-strokeBlue ro-font-semibold hover:ro-underline">{t('share.goToFacebook')}</a>

                                    </div>
                                </div>
                            : selectedTab === "instagram" ? 
                                <div className="ro-flex ro-flex-[2] ro-flex-col ro-gap-6">

                                    <h2 className="ro-font-poppins ro-text-3xl ro-text-strokeBlue ro-font-semibold">{t('share.shareTimeInstagram')}</h2>
                                    <p className="ro-font-poppins ro-text-xl ro-text-strokeBlue ro-w-2/3">{t('share.downloadForInstagram')}</p>
                                    <div className="ro-flex ro-flex-row ro-gap-6 ro-items-center">
                                        {
                                            image.current && image.current ?
                                                <div onClick={downloadImage} role="button" className="ro-bg-strokeGreen ro-px-6 ro-py-3 ro-text-white ro-font-poppins ro-font-semibold ro-cursor-pointer ro-transition-all hover:ro-scale-105">{t('share.downloadingImage')}</div>
                                            :
                                                <div className="ro-bg-strokeGreen ro-px-6 ro-py-3 ro-text-white ro-font-poppins ro-font-semibold">Creating your image</div>
                                        }
                                        <a href="https://instagram.com" target="_blank" rel="noreferrer" className="ro-font-poppins ro-text-strokeBlue ro-font-semibold hover:ro-underline">{t('share.goToInstagram')}</a>

                                    </div>
                                </div>
                            : selectedTab === "twitter" ? 
                                <div className="ro-flex ro-flex-[2] ro-flex-col ro-gap-6">

                                    <h2 className="ro-font-poppins ro-text-3xl ro-text-strokeBlue ro-font-semibold">{t('share.shareTimeTwitter')}</h2>
                                    <p className="ro-font-poppins ro-text-xl ro-text-strokeBlue ro-w-2/3">{t('share.downloadForTwitter')}</p>
                                    <div className="ro-flex ro-flex-row ro-gap-6 ro-items-center">
                                        {
                                            image.current ?
                                                <div onClick={downloadImage} role="button" className="ro-bg-strokeGreen ro-px-6 ro-py-3 ro-text-white ro-font-poppins ro-font-semibold ro-cursor-pointer ro-transition-all hover:ro-scale-105">{t('share.downloadingImage')}</div>
                                            :
                                                <div className="ro-bg-strokeGreen ro-px-6 ro-py-3 ro-text-white ro-font-poppins ro-font-semibold">Creating your image</div>
                                        }
                                        <a href="https://twitter.com" target="_blank" rel="noreferrer" className="ro-font-poppins ro-text-strokeBlue ro-font-semibold hover:ro-underline">{t('share.goToTwitter')}</a>

                                    </div>
                                </div>
                            : null
                        }
                        <div className="ro-flex ro-flex-1">
                            <Share type={selectedTab} time={props.time} ref={image} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Share
export { ShareModal }