import React, { useState, useEffect, useRef } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate, Link } from "react-router-dom";

import GreenButton from "../Components/GreenButton";
import { Icon } from "../Components/Icons";
import { Platform } from "../Components/Platform";
import { LanguageSwitcher } from "../Components/LanguageSwitcher";

import { useTranslation } from 'react-i18next';
import {FaceIcon} from "../Components/faceIcon";
import {ArmsIcon} from "../Components/armsIcon";
import {SpeechIcon} from "../Components/speechIcon";

function Landing(){
    const { t, i18n } = useTranslation(['translation']);

    const platform = new Platform();
    const [loaded, setLoaded] = useState(0);
    let navigate = useNavigate();
    const firstIcon = useRef<any>();

    const [modal, setModal] = useState(false);

    useEffect(() => {
        // Initial delay
        let timeout = 500;

        // Load in h1
        setTimeout(() => {
            setLoaded(1);
        }, timeout);
        timeout += 200;

        // Load in first icon
        setTimeout(() => {
            setLoaded(2);
        }, timeout);
        timeout += 100;

        // Load in second icon
        setTimeout(() => {
            setLoaded(3);
        }, timeout);
        timeout += 100;

        // Load in third icon and first line
        setTimeout(() => {
            setLoaded(4);
        }, timeout);
        timeout += 100;

        // Load in fourth icon and second line
        setTimeout(() => {
            setLoaded(5);
        }, timeout);
        timeout += 100;

        // Load in arrow
        setTimeout(() => {
            setLoaded(6);
        }, timeout);
        timeout += 100;
    }, []);

    const startGame = () => {
        setLoaded(9);

        setTimeout(() => {
            setLoaded(10);

            setTimeout(() => {
                navigate("/game");
            }, 500);
        }, 500);
    }

    const displayInfo = () => {
        if (!platform.embed){
            setModal(true);
        } else {
            startGame();
        }
    }

    return (

        <div className={`${platform.embed ? "ro-w-full ro-aspect-video" : "ro-w-screen ro-h-screen"} ro-bg ro-game ro-relative ro-overflow-hidden`}>

            <section className={`portrait:ro-hidden landscape:ro-flex ro-hidden xl:ro-flex bg landing ro-w-full ro-h-full ro-items-center ro-justify-center ro-flex-col ro-gap-6 xl:ro-gap-12 ro-transition-all ro-duration-500 ${loaded > 9 ? "ro-translate-y-full ro-scale-75 ro-rounded-3xl ro-opacity-0" : loaded > 8 ? "ro-rounded-3xl ro-scale-75" : "ro-scale-1 ro-rounded-none"}`}>

                {/*<LanguageSwitcher bgColour="ro-bg-white" textColour="ro-text-strokeBlue"/>*/}

                <h1 className={`ro-font-semibold ro-font-poppins ro-text-white  ro-text-3xl xl:ro-text-6xl ro-transition-all ro-max-w-2xl ro-text-center ${loaded > 0 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`}>{t('initialPage.pageTitle')}</h1>

                <div className={`ro-flex ro-flex-row ro-gap-0 ro-items-center`}>
                    <Icon correct type={ i18n.language == 'en' ? "face" : "speech"} className={`ro-z-10 ro-relative ro-transition ${loaded > 1 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} ref={firstIcon} />
                    <ConnectLine className={`${loaded > 3 ? "ro-opacity-1 ro-translate-x-0" : "ro-opacity-0 -ro-translate-x-14"}`} />
                    <Icon type="arms" correct className={`ro-z-10 ro-relative ro-transition ${loaded > 2 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} />
                    <ConnectLine className={`${loaded > 4 ? "ro-opacity-1 ro-translate-x-0" : "ro-opacity-0 -ro-translate-x-14"}`} />
                    <Icon type={ i18n.language == 'en' ? "speech" : "face"} correct className={`ro-z-10 ro-relative ro-transition ${loaded > 3 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} />
                    <EndArrow className={`${loaded > 5 ? "ro-opacity-1 ro-translate-x-0" : "ro-opacity-0 -ro-translate-x-12"}`} />
                    <Icon type="time" correct className={`ro-z-10 ro-relative ro-transition ${loaded > 3 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`} />
                </div>

                <h2 className={`ro-font-poppins ro-text-xl ro-text-center xl:ro-text-3xl ro-text-white ro-font-semibold ro-transition-all ro-px-8 xl:ro-px-0 ${loaded > 2 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 ro-translate-y-4"}`}>{t('initialPage.subTitle')}</h2>
                <GreenButton className={`ro-transition-all ${loaded > 4 ? "ro-opacity-1 ro-scale-100" : "ro-opacity-0 ro-scale-90"}`} onClick={displayInfo}>
                    <h3 className="ro-font-poppins ro-text-white ro-font-semibold ro-text-xl xl:ro-text-2xl">{t('global.start')}</h3>
                </GreenButton>
                <Link to="/leaderboard" className="ro-no-underline hover:ro-no-underline"><div className={`ro-flex ro-flex-row ro-text-white ro-font-poppins ro-font-semibold ro-text-lg ro-pb-0.5 ro-mt-0 ro-items-center ro-tracking-wide ro-relative after:ro-content-[''] after:ro-absolute after:ro-top-full after:ro-h-0.5 after:ro-w-full after:ro-bg-white ro-overflow-hidden after:-ro-translate-x-full after:ro-transition-all hover:after:ro-translate-x-0 after:-ro-translate-y-0.5 after:ro-opacity-0 hover:after:ro-opacity-100 transition-all ${loaded > 4 ? "ro-opacity-1 ro-translate-y-0" : "ro-opacity-0 -ro-translate-y-4"}`} role="button">{t('global.viewLeaderboard')}<HiChevronRight /></div></Link>
            </section>

            <div className={`ro-w-full ro-h-full ro-absolute ro-left-0 ro-top-0 ro-z-50 ro-flex ro-items-center ro-justify-center ${modal ? "ro-pointer-events-auto" : "ro-pointer-events-none"}`}>
                <div className={`ro-absolute ro-w-full ro-h-full ro-left-0 ro-top-0 ro-bg-strokeBlue/70 ro-backdrop-blur-lg ro-transition-opacity ${modal ? "ro-opacity-1" : "ro-opacity-0"}`}></div>
                <div className={`ro-flex ro-flex-col ro-items-center ro-transition-all ${modal ? "ro-opacity-1 ro-scale-100" : "ro-opacity-0 ro-scale-95"}`}>
                    <h1 className="ro-font-poppins ro-font-semibold ro-text-3xl xl:ro-text-6xl ro-text-white ro-mb-16">{t('global.remember')}...</h1>
                    <div className="ro-flex ro-flex-row ro-gap-0 ro-items-center">
                        <div className="ro-flex ro-flex-col ro-items-center">
                            <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{ i18n.language == 'en' ? t('global.face') : t('global.speech')} </span>
                            <Icon correct type={ i18n.language == 'en' ? "face" : "speech"} />
                        </div>
                        <ConnectLine />
                        <div className="ro-flex ro-flex-col ro-items-center">
                            <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{t('global.arms')}</span>
                            <Icon correct type="arms" />
                        </div>
                        <ConnectLine />
                        <div className="ro-flex ro-flex-col ro-items-center">
                            <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{ i18n.language == 'en' ? t('global.speech') : t('global.face')}</span>
                            <Icon correct type={ i18n.language == 'en' ? "speech" : "face"} />
                        </div>
                        <EndArrow />
                        <div className="ro-flex ro-flex-col ro-items-center">
                            <span className="ro-absolute ro-pointer-events-none ro-bg-white ro-text-sm ro-rounded-full ro-flex ro-flex-col ro-items-center -ro-translate-y-full -ro-mt-2 ro-font-poppins ro-font-semibold ro-text-strokeBlue ro-px-4 ro-py-1">{t('global.time')}</span>
                            <Icon correct type="time" />
                        </div>
                    </div>
                    <h2 className="ro-font-poppins ro-font-normal ro-text-xl xl:ro-text-3xl ro-text-white ro-mt-12">
                        <span dangerouslySetInnerHTML={ {__html: t('game.theseAreTheSigns', {interpolation: {escapeValue: false}})} } />
                    </h2>
                    <div className="ro-flex ro-flex-row ro-bg-strokeGreen ro-text-white ro-font-poppins ro-py-2 xl:ro-py-4 ro-mt-5 ro-px-4 xl:ro-px-8 ro-rounded-full ro-items-center ro-justify-center ro-font-semibold ro-text-md xl:ro-text-lg ro-gap-1 ro-shadow-md ro-cursor-pointer ro-transition-all hover:-ro-translate-y-1" onClick={() => {
                        setModal(false);
                        setTimeout(() => {
                            startGame();
                        }, 300);
                    }}>{t('global.continue')} <HiChevronRight className="ro-text-lg xl:ro-text-2xl" /></div>
                </div>
            </div>

            <div className="ro-w-full ro-h-full landscape:ro-hidden xl:ro-hidden ro-bg-strokeBlue ro-flex ro-flex-col ro-items-center ro-justify-center ro-gap-12 ro-z-50 ro-absolute ro-top-0 ro-left-0">
                <h1 className="ro-font-poppins ro-text-white ro-font-semibold ro-text-3xl lg:ro-text-4xl xl:ro-text-5xl 2xl:ro-text-6xl ro-px-6 ro-text-center">{t('global.rotateDevice')}</h1>
            </div>
        </div>
    )
}

interface LineProps {
    /**
     * @property {string} [className] Add additional classes to the component
     */
    className?: string
    /**
     * @property {boolean} [blue] Toggle colour of lines and borders to blue
     */
    blue?: boolean
}

function ConnectLine({className = undefined, blue = false}: LineProps){
    return(
        <div className={`ro-h-0.5 xl:ro-h-1 ro-w-4 xl:ro-w-14 ${blue ? "ro-bg-strokeBlue" : "ro-bg-white"} ${className ? className : ""} ro-transition-all`}></div>
    )
}

function EndArrow({className = undefined, blue = false}: LineProps){
    return(
        <div className={`ro-h-0.5 xl:ro-h-1 ro-w-5 xl:ro-w-12 ro-relative ${blue ? "ro-bg-strokeBlue" : "ro-bg-white"} ro-mr-2 xl:ro-mr-4 ro-flex ro-items-center ${className ? className : ""} ro-transition-all`}>
            <span className={`ro-absolute ${blue ? "ro-bg-strokeBlue" : "ro-bg-white"} ro-right-0 ro-h-0.5 xl:ro-h-1 ro-w-3 xl:ro-w-7 ro-origin-right ro-rotate-45 ro-rounded-full ro-mt-[1px] xl:ro-mt-[2.5px] -ro-mr-[1px]`}></span>
            <span className={`ro-absolute ${blue ? "ro-bg-strokeBlue" : "ro-bg-white"} ro-right-0 ro-h-0.5 xl:ro-h-1 ro-w-3 xl:ro-w-7 ro-origin-right -ro-rotate-45 ro-rounded-full -ro-mt-[1px] xl:-ro-mt-[2.5px] -ro-mr-[1px]`}></span>
        </div>
    )
}

export default Landing

export { ConnectLine, EndArrow }