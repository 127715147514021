import {
	HashRouter,
	Routes,
	Route
} from "react-router-dom";
import Landing from "./Views/Landing";
import Game from "./Views/Game";
import Leaderboard from "./Views/Leaderboard";
import './i18n/config';


function App(){

	return(
		<div className="flex flex-col gap-0">
			<HashRouter>
				<Routes>
					<Route path="/" element={<Landing />} />
					<Route path="/game" element={<Game />} />
					<Route path="/leaderboard" element={<Leaderboard />} />
				</Routes>
			</HashRouter>
		</div>
	)
}

export default App;
